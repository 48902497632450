.nav-link {
  color: #0274be !important;
  padding: 0 1rem !important;
  font-size: 15px;
}

.nav-link a {
  color: #0274be !important;
}

.nav-link:hover {
  color: #3a3a3a !important;
}

.seperator {
  width: 30%;
  border-top-width: 2px;
  border-top-color: #333;
  border-top-style: solid;
  margin-top: 15px;
  margin-bottom: 15px;
}

.blue {
  color: #0274be !important;
}

h2 {
  font-weight: 400;
  margin-bottom: 30px !important;
}

.a-link {
  color: #0274be !important;
}

.a-link:hover {
  color: #3a3a3a !important;
}

.button-link {
  background-color: #0274be !important;
}

.button-link:hover {
  background-color: #3a3a3a !important;
}

.innoware-carousel-item {
  height: 250px;
}

@media (max-width: 1200px) {
  .innoware-carousel-item {
    height: 350px;
  }
}

@media (max-width: 768px) {
  .innoware-carousel-item {
    height: 450px;
  }
}
